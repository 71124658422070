import { Tooltip } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import { recordActions } from '../../ducks/records';

import { studentOperations } from '../../ducks/students';


const styles = theme => ({
  bar: {
    color: theme.palette.primary.main
  }
})

const customStyles = {
  control: (base, state) => ({
    ...base,
    boxShadow: state.isFocused ? `${alpha('#EF6C00', 0.25)} 0 0 0 0.1rem` : 0,
    border: state.isFocused
      ? '1px solid #EF6C00'
      : '1px solid rgba(0,0,0,0)',
    '&:hover': {
      border: state.isFocused
        ? '1px solid #EF6C00'
        : '1px solid rgba(0,0,0,0)'
    }
  })
}

const StudentSelect = props => {
  const { selectStudent, fetchStudents, student, students, studentsQuery, loading, classes, isDisabled } = props

  const groups = Object.values(students.reduce((studentGroups, student) => {
    const groupTitle = student.value.attendant === undefined ? 'unregistered' : 'registered';
    if (!studentGroups[groupTitle]) {
      studentGroups[groupTitle] = {
        label: groupTitle,
        options: []
      }
    }
    studentGroups[groupTitle].options.push(student)
    return studentGroups;
  }, {}))


  const formatGroupLabel = data => (
    <div>
      <span>{data.label === 'registered' ? 'Für Kurs angemeldet' : 'Andere'}</span>
      <span> ({data.options.length})</span>
    </div>
  );

  const selectedStudent = student && { value: student, label: `${student.firstName} ${student.lastName}`}

  const formatNoOptionsMessage = ({inputValue}) => {
    if (inputValue.length > 1) {
      return `Kein Ergebnis für "${inputValue}"`
    }
    return 'Mindestens zwei Buchstaben eingeben, um Suche zu starten.';
  }

  const formatLoadingMessage = () => {
    return 'Suche Studierende...';
  }

  const handleChange = (option) => {
    selectStudent(option ? option.value : null)
  }
  const handleInputChange = input => {
    const query = {...studentsQuery, query: input, more: false}
    if (input.length > 1) {
      if (query.eventIds) {
        // TODO: this is a temporary fix until we added a button to "enable show more" explicitly
        query.more = true;
      }
      return fetchStudents(query)
    }
    // If we have an event id, load students per default
    if (query.eventIds && input.length === 0 && input !== studentsQuery.query) {
      return fetchStudents(query)
    }
  }
  const select = <Select
    value={selectedStudent}
    onChange={handleChange}
    onInputChange={handleInputChange}
    options={groups}
    formatGroupLabel={formatGroupLabel}
    isLoading={loading}
    className={classes.bar}
    styles={customStyles}
    isClearable={student}
    isDisabled={isDisabled}
    placeholder={'Student*in'}
    noOptionsMessage={formatNoOptionsMessage}
    loadingMessage={formatLoadingMessage}
  />
  return (
    isDisabled ? <Tooltip title="Student:in kann nachträglich nicht verändert werden."><div>{ select }</div></Tooltip> : select
  )
}

const mapStateToProps = ({ student, record }) => ({
  students: student.students,
  studentsQuery: student.queryParams,
  student: record.record.student,
  loading: student.loading
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchStudents: studentOperations.index,
      selectStudent: recordActions.setStudent
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(StudentSelect))