import { withStyles } from '@material-ui/core';
import Slider from '@material-ui/core/Slider/Slider';
import React from 'react';

const marks = [
  { value: 0, label: '0' },
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
]

const StyledSlider = withStyles({
  rail: {
    height: 5
  },
  mark: {
    height: 5
  },
  track: {
    height: 5
  },
  thumb: {
    marginTop: -4
  },
  markLabel: {
    fontWeight: 'bold',
    fontSize: '1rem'
  }
})(Slider)

const SupervisionScale = props => {
  const { value, onChange } = props

  const comp = props => value !== null ? <span {...props}/> : <span {...props} style={{opacity: 0}}/>

  return (
    <StyledSlider
      value={value}
      step={1}
      min={0}
      max={5}
      color={'secondary'}
      ThumbComponent={comp}
      marks={marks}
      onChange={onChange}
    />
  )
}

export default SupervisionScale