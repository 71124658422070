import Chip from '@material-ui/core/Chip/Chip';
import Grid from '@material-ui/core/Grid/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import React from 'react';

import epaJson from '../../assets/epas';

const ItemChip = withStyles(theme => ({
  root: {
    color: '#fff',
    margin: theme.spacing(1 / 5),
    borderRadius: theme.spacing(0.5),
    backgroundColor: '#ccc',
    fontWeight: 600
  },
  errorInner: {
    backgroundColor: '#e64a19',
    '&:hover': {
      backgroundColor: '#e64a19a1',
    },
    '&:focus': {
      backgroundColor: '#e64a19a1',
    },
  },
  errorOuter: {
    border: '3px solid #e64a19',
  },
  warningInner: {
    backgroundColor: '#cddc39',
    '&:hover': {
      backgroundColor: '#cddc39a1',
    },
    '&:focus': {
      backgroundColor: '#cddc39a1',
    },
  },
  warningOuter: {
    border: '3px solid #cddc39',
  },
  successInner: {
    backgroundColor: '#388E3C',
    '&:hover': {
      backgroundColor: '#388E3Ca1',
    },
    '&:focus': {
      backgroundColor: '#388E3Ca1',
    },
  },
  successOuter: {
    border: '3px solid #388E3C',
  },
}))(({record, recordItem, classes, ...rest}) => {

  let filledOut = false
  const classNames = [classes.root]
  const epaDefinition = epaJson.find(epa => epa.id === recordItem.id)
  let message = epaDefinition.title
  const type = recordItem.id === 14 ? 'social-competencies' : recordItem.id === 15 ? 'mini-cex' : 'epa'
  const label = recordItem.id === 14 ? 'SK' : recordItem.id === 15 ? 'MiC' : recordItem.id
  switch (type) {
    case 'epa':
      filledOut = recordItem.supervision !== null || !!recordItem.competencies.length
      if (recordItem.supervision !== null) {
        classNames.push(classes.successInner)
        if (!recordItem.competencies.length) {
          classNames.push(classes.warningOuter)
        }
      } else {
        classNames.push(classes.errorInner)
        if (recordItem.competencies.length) {
          classNames.push(classes.successOuter)
        }
      }
      message = `${message}: ${recordItem.supervision !== null ? recordItem.supervision : '-'}`
      break;
    case 'social-competencies':
      filledOut = !!recordItem.competencies
      if (!filledOut) break;
      switch (recordItem.competencies.length) {
        case 3:
          classNames.push(classes.successInner)
          break;

        default:
          classNames.push(classes.errorInner)
          break;
      }
      message = `${message}: ${getCompetenciesOverview(recordItem).join(' / ')}`
      break;

    case 'mini-cex':
      filledOut = !!record.role || !!recordItem.competencies
      if (!filledOut) break;

      const optionalCompetencyIsMissing = !recordItem.competencies.find(c => c.competencyId === 3)
      const missingCompetencies = 9 - recordItem.competencies.length

      if (missingCompetencies >= 2 || (missingCompetencies === 1 && !optionalCompetencyIsMissing)) {
        classNames.push(classes.errorInner)
      } else if (optionalCompetencyIsMissing) {
        classNames.push(classes.warningInner)
      } else {
        classNames.push(classes.successInner)
      }
      message = `${message}: ${getCompetenciesOverview(recordItem).join(' / ')}`
      if (record.role) {
        classNames.push(classes.successOuter)
        message = `${message} (${record.role})`
      } else {
        classNames.push(classes.errorOuter)
      }
      break;

    default:
      break;
  }

  return (<Tooltip
    title={message}
    placement="bottom">
    <Grid item>
      <Chip size="small" label={label} clickable={filledOut} className={classNames.join(' ')} {...rest}/>
    </Grid>
  </Tooltip>)
})

const getCompetenciesOverview = item => {
  const config = epaJson.find(itemConfig => itemConfig.id === item.id)
  return ((config && config.competencies) || [])
    .map(competency =>
      ((item.competencies || []).find(c => c.competencyId === competency.id) || {}).value || '-'
    )
}

export default ItemChip