import moment from 'moment';
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';

import { observationAssignmentActions } from '../../ducks/observation-assignments';
import { recordActions, recordOperations } from '../../ducks/records';
import { taskOperations } from '../../ducks/tasks';
import FormContainer from './FormContainer';
import RecordsDrawer from './RecordsDrawer';
import TabContainer from './TabContainer';

class Form extends React.Component {

  onChangeTab = index => {
    this.props.selectEpa(index)
  }

  onChangeRecord = record => {
    if (!record || record.id === -1) {
      this.props.history.push(`/tasks/${this.props.match.params.caseId}/forms`)
    } else {
      this.props.history.push(`/tasks/${record.case.id}/forms/${record.id}`)
    }
  }

  componentDidMount() {
    const { task, selectTaskById, selectRecordById, selectTask, match, activeObservationAssignment, setActiveObservationAssignment, setStudent, setObservationTime } = this.props
    const { caseId: taskId, recordId} = match.params
    const taskPromise = new Promise(resolve => {
      if (!task || task.id.toString() !== taskId) {
        return selectTaskById(taskId)
        .then(selectedTask => selectTask(selectedTask))
        .then(selectedTask => resolve(selectedTask))
      }
      return resolve(task);
    })
    taskPromise.then(_ => {
      selectRecordById(recordId)
      if (activeObservationAssignment) {
        setStudent(activeObservationAssignment.student)
        if (activeObservationAssignment.performedAt) {
          setObservationTime(moment(activeObservationAssignment.performedAt).format('DD.MM.YYYY, HH:mm:ss'))
        }
        setActiveObservationAssignment(null)
      }
    });
  }

  componentDidUpdate(prevProps) {
    const prevMatchParams = prevProps.match.params;
    const matchParams = this.props.match.params;
    if (prevMatchParams.recordId !== matchParams.recordId) {
      this.props.selectRecordById(matchParams.recordId)
    }
  }

  render() {
    const { epas, index, taskIsLoading, roomIsLoading, recordValidationErrors } = this.props

    return (
     taskIsLoading || roomIsLoading ? null :
        <RecordsDrawer open={true} onSelectRecord={this.onChangeRecord}>
          <TabContainer list={epas} value={index} validationErrors={recordValidationErrors} onChange={this.onChangeTab}>
            <FormContainer/>
          </TabContainer>
        </RecordsDrawer>
    )
  }
}

const mapStateToProps = ({ room, record, task, observationAssignment }) => ({
  room: room.room,
  task: task.task,
  currentRecord: record.record,
  taskIsLoading: task.loading,
  roomIsLoading: room.loading,
  epas: record.epas,
  index: record.selectedIndex,
  recordValidationErrors: record.validationErrorsVisible ? record.validationErrors : [],
  activeObservationAssignment: observationAssignment.active,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      selectEpa: recordActions.selectEpa,
      selectRecordById: recordOperations.selectById,
      setStudent: recordActions.setStudent,
      setObservationTime: recordActions.setObservationTime,
      selectTask: taskOperations.select,
      selectTaskById: taskOperations.selectById,
      setActiveObservationAssignment: observationAssignmentActions.setActive,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form)
