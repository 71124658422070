import { Tooltip } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl/FormControl';
import Grid from '@material-ui/core/Grid/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputBase from '@material-ui/core/InputBase/InputBase';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import Select from '@material-ui/core/Select/Select';
import { alpha } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography/Typography';
import TaskIcon from '@material-ui/icons/Assignment';
import ChatIcon from '@material-ui/icons/Chat';
import RoomIcon from '@material-ui/icons/Room';
import ScheduleIcon from '@material-ui/icons/Schedule';
import clsx from 'clsx';
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';

import config from '../../config/config';
import { recordActions } from '../../ducks/records';
import { roomOperations } from '../../ducks/rooms';
import GlobalRatingDialog from './GlobalRatingDialog';
import ObservationTimeDialog from './ObservationTimeDialog';
import Photo from './Photo';
import StudentSelect from './StudentSelect';

const styles = theme => ({
  formControl: {
    width: 240,
    margin: theme.spacing(0.5),
    [theme.breakpoints.down('md')]: {
      width: 175,
      marginRight: theme.spacing(0)
    }
  },
  room: {
    minWidth: 50,
    width: 'auto'
  },
  selectEmpty: {
    borderRadius: 4,
    color: '#fff'
  },
  selectIcon: {
    fill: '#fff'
  },
  roomTitle: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(4)
  },
  taskTitle: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(1)
    }
  }
})

const BootstrapInput = withStyles(theme => ({
  root: {
    borderRadius: 4,
    border: '1px solid rgba(0,0,0,0)',
    backgroundColor: theme.palette.common.white,
  },
  input: {
    borderRight: '1px solid',
    borderRightColor: theme.palette.divider,
    height: 36,
    padding: '0px 8px',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica"',
      'Arial',
      'sans-serif'
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.secondary.main, 0.25)} 0 0 0 0.1rem`,
      border: `1px solid ${theme.palette.secondary.main}`
    }
  },
}))(props => {
  if (props.endAdornment) {
    const BootstrapInputAdornment = withStyles(theme => ({
      positionEnd: {
        marginLeft: 0,
      }
    }))(InputAdornment)
    props = {...props, endAdornment: (<BootstrapInputAdornment {...props.endAdornment.props}/>)}
  }
  return (<InputBase {...props}/>)}
)

const SquareIconButton = withStyles(theme => ({
  root: {
    padding: 10,
    borderRadius: 0,
  }
}))(IconButton)

class Subheader extends React.Component {

  state = {
    globalRatigDialogOpen: false,
    observationTimeDialogOpen: false,
  }

  componentDidMount() {}

  handleChangeGlobalRating = event => {
    const globalRating = event.target.value
    this.props.changeGlobalRating(globalRating.split('↵').join("\n"))
  }

  handleChangeRoom = event => {
    const { room, selectRoomById } = this.props
    if (room.id !== event.target.value.id && window.confirm('Bestehende Formulareinträge werden bei einem Stationswechsel nicht gespeichert und automatisch gelöscht. Fortfahren?')) {
      selectRoomById(event.target.value.id)
    }
  }

  renderRoomSelect = () => {
    const { rooms, room, classes } = this.props
    return (
      <Grid item>
        <Grid container direction={'row'} alignItems={'center'}>
          <RoomIcon fontSize={'small'}/>
          <FormControl className={clsx(classes.formControl, classes.room)}>
            <Select
              labelId="room-select-label"
              id="room-select"
              renderValue={value => value.title}
              value={room}
              placeholder={'Raum'}
              className={classes.selectEmpty}
              disableUnderline
              inputProps={{
                classes: {
                  icon: classes.selectIcon
                }
              }}
              onChange={this.handleChangeRoom}
            >
              {rooms.map((r, i) => (
                <MenuItem key={i} value={r}><ListItemText primary={r.title}/></MenuItem>))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    )
  }

  renderTaskInfo = () => {
    const { task, classes } = this.props
    return (
      <Grid item>
        <Grid container direction={'row'} alignItems={'center'}>
          <TaskIcon fontSize={'small'}/>
          <Grid item>
            <Typography variant={'body1'}
                        className={classes.taskTitle}>{task ? task.title : 'n.A.'}</Typography>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  renderTimeInfo = () => {
    const { record, classes } = this.props
    const { observationTimeDialogOpen } = this.state

    const timeInfo = record.observedAt === undefined ? 'Jetzt' : record.observedAt

    return (
      <Grid item>
        <Grid container direction={'row'} alignItems={'center'}>
          <ScheduleIcon fontSize={'small'}/>
          <Grid item>
            <Tooltip title="Beobachtungszeitpunkt">
              <Typography variant={'body1'}
                          className={clsx(classes.taskTitle, 'clickable')}
                          onClick={this.handleOpenObservationTimeDialog}
                        >{timeInfo}</Typography>
            </Tooltip>
          </Grid>
        </Grid>
        <ObservationTimeDialog open={observationTimeDialogOpen} onClose={this.handleCloseObservationTimeDialog} value={record.observedAt}/>
      </Grid>
    )
  }

  handleOpenObservationTimeDialog = () => {
    this.setState({ observationTimeDialogOpen: true })
  }
  handleCloseObservationTimeDialog = value => {
    this.setState({ observationTimeDialogOpen: false })
    if (value !== undefined) {
      value = value || this.props.record.createdAt
      this.props.changeObservationTime(value)
    }
    console.log('new observation time', value)
  }

  handleOpenGlobalRatingDialog = () => {
    this.setState({ globalRatigDialogOpen: true })
  }

  handleCloseGlobalRatingDialog = updatedGlobalRating => {
    this.setState({ globalRatigDialogOpen: false })
    this.props.changeGlobalRating(updatedGlobalRating)
  }

  renderGlobalRating = () => {
    const { classes, globalRating } = this.props
    const { globalRatigDialogOpen } = this.state
    return (
      <Grid item>
        <FormControl className={classes.formControl}>
          <BootstrapInput
            onChange={this.handleChangeGlobalRating}
            value={globalRating.split("\n").join('↵')}
            placeholder={'Global-Rating'}
            endAdornment={
              <InputAdornment position="end">
                <Tooltip title="Textfeld vergößern">
                  <SquareIconButton
                    onClick={this.handleOpenGlobalRatingDialog}
                    aria-label="Show full content"
                  >
                    <ChatIcon/>
                  </SquareIconButton>
                </Tooltip>
              </InputAdornment>
            }
          />
        </FormControl>
        <GlobalRatingDialog open={globalRatigDialogOpen} onClose={this.handleCloseGlobalRatingDialog} value={globalRating}/>
      </Grid>
    )
  }

  renderStudentSelect = () => {
    const { classes, record, user } = this.props
    const { requiredPermissions } = config
    // TODO: centralize user permissions handling
    const canUpdateAllRecords = user.permissions.filter(userPermission => requiredPermissions.updateRecord.includes(userPermission)).length > 0;
    return (
      <>
        <Grid item>
          <FormControl className={classes.formControl}>
            <StudentSelect isDisabled={record.id !== -1 && !canUpdateAllRecords}/>
          </FormControl>
        </Grid>
        <Grid item>
          <Photo student={record.student}/>
        </Grid>
      </>
    )
  }

  render() {
    const { classes } = this.props
    return (
      <Grid container direction={'row'} className={classes.bar} justifyContent={'flex-end'}
            alignItems={'center'} spacing={1}>
          {this.renderTimeInfo()}
          {this.renderTaskInfo()}
          {/* {this.renderRoomSelect()} */}
          {this.renderGlobalRating()}
          {this.renderStudentSelect()}
      </Grid>
    )
  }
}

const mapStateToProps = ({ room, task, record, user }) => ({
  room: room.room,
  rooms: room.rooms,
  task: task.task,
  globalRating: record.record.globalRating,
  record: record.record,
  user: user.user,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeGlobalRating: recordActions.setGlobalRating,
      changeObservationTime: recordActions.setObservationTime,
      fetchRooms: roomOperations.index,
      selectRoomById: roomOperations.selectById
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Subheader))
