const BASE_PATH = 'api/rooms/'

const types = {
  SELECT_ROOM: BASE_PATH + 'SELECT_ROOM',
  ROOM_LIST_REQUEST: BASE_PATH + 'ROOM_LIST_REQUEST',
  ROOM_LIST_FAILURE: BASE_PATH + 'ROOM_LIST_FAILURE',
  ROOM_LIST_SUCCESS: BASE_PATH + 'ROOM_LIST_SUCCESS',
  ROOM_GET_REQUEST: BASE_PATH + 'ROOM_GET_REQUEST',
  ROOM_GET_FAILURE: BASE_PATH + 'ROOM_GET_FAILURE',
  ROOM_GET_SUCCESS: BASE_PATH + 'ROOM_GET_SUCCESS',
  MACHINE_REQUEST: BASE_PATH + 'MACHINE_REQUEST',
  MACHINE_FAILURE: BASE_PATH + 'MACHINE_FAILURE',
  MACHINE_SUCCESS: BASE_PATH + 'MACHINE_SUCCESS',
  RESET: BASE_PATH + 'RESET_ROOM'
}

export default types
