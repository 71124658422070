import { colors, Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import IconButton from '@material-ui/core/IconButton/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography/Typography';
import InfoIcon from '@material-ui/icons/Info';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import clsx from 'clsx';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { recordActions } from '../../ducks/records';
import { selectVisibleValidationErrors } from '../../ducks/records/reducers';
import SupervisionHelpDialog from './SupervisionHelpDialog';
import SupervisionScale from './SupervisionScale';

const styles = theme => ({
  wrapper: {
    border: '2px solid',
    borderColor: colors.grey[300],
    background: '#e3f2fd63',
    borderRadius: '.5rem',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  hasErrors: {
    border: '2px solid',
    borderColor: colors.red[900],
  },
  supervisionTitle: {
    fontSize: '1.3rem'
  }
})

const EpaSupervisionBlock = props => {

  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const { classes, changeSupervision, form, recordValidationErrors } = props
  const storedValue = form.supervision === undefined ? null : form.supervision;
  const hasError = recordValidationErrors.find(error => error.property === 'supervision')
  return (
    <>
      <Grid container direction={'column'} className={clsx(classes.wrapper, hasError && classes.hasErrors)}>
        <Grid item>
          <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography className={classes.supervisionTitle} gutterBottom>
              Supervisionslevel
            </Typography>
            <div>
              <IconButton onClick={handleOpen} aria-label="info">
                <Tooltip title="Erklärung der Skala">
                  <InfoIcon/>
                </Tooltip>
              </IconButton>
              <IconButton color="primary" disabled={storedValue === null} onClick={() => changeSupervision(undefined)} aria-label="reset">
                <Tooltip title="Skala zurücksetzen">
                  <SettingsBackupRestoreIcon/>
                </Tooltip>
              </IconButton>
            </div>
          </Grid>
        </Grid>
        <Grid item>
          <SupervisionScale value={storedValue} onChange={(event, value) => changeSupervision(value)}/>
        </Grid>
      </Grid>
      <SupervisionHelpDialog open={open} handleClose={handleClose}/>
    </>
  )
}

const mapStateToProps = ({ record }) => ({
  form: selectCurrentForm(record),
  recordValidationErrors: selectVisibleValidationErrors(record),
})

const selectCurrentForm = recordState => {
  return {
    ...recordState.selectedEpa,
    ...recordState.record.epas.find(epa => epa.id === recordState.selectedEpa.id)
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeSupervision: recordActions.setSupervisionLevel
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EpaSupervisionBlock))
