import types from './types'


export const actions = {
  reset: () => ({
    type: types.RESET
  }),

  selectRoom: room => ({
    type: types.SELECT_ROOM,
    payload: room
  }),

  roomListRequest: () => ({
    type: types.ROOM_LIST_REQUEST
  }),

  roomListFailure: error => ({
    type: types.ROOM_LIST_FAILURE,
    payload: error
  }),
  roomListSuccess: list => ({
    type: types.ROOM_LIST_SUCCESS,
    payload: list
  }),
  roomGetRequest: () => ({
    type: types.ROOM_GET_REQUEST
  }),
  roomGetFailure: error => ({
    type: types.ROOM_GET_FAILURE,
    payload: error
  }),
  roomGetSuccess: room => ({
    type: types.ROOM_GET_SUCCESS,
    payload: room
  }),
  machineRequest: () => ({
    type: types.MACHINE_REQUEST
  }),
  machineFailure: error => ({
    type: types.MACHINE_FAILURE,
    payload: error
  }),
  machineSuccess: machine => ({
    type: types.MACHINE_SUCCESS,
    payload: machine
  })

}

export default actions
